export enum SubMenuStatisticsNames {
    heuresProduites = '0',
    followupManager = '4',
    marginProfit = '6',
    resume = '7', // fixme ULTIMATE -> should have I added the operations one here or should it be totally different enum ?
    invoiceManagement = '8'
}

export const subMenuMap = {
    [SubMenuStatisticsNames.heuresProduites]: {
        name: 'heures_produites',
        id: 'a8aaf5b0-e5ad-11e9-ad07-4b541fd65f63'
    },
    [SubMenuStatisticsNames.followupManager]: {
        name: 'followup_manager',
        id: 'ad1548e0-59c7-11ec-a3a8-0ff9c392ebe7'
    },
    [SubMenuStatisticsNames.marginProfit]: {
        name: 'margin_profit',
        id: '03dde870-5744-11ec-8e9e-9d5152b31880'
    },
    [SubMenuStatisticsNames.resume]: {
        name: 'resume',
        id: 'b6624350-5786-11ec-8e9e-9d5152b31880'
    },
    [SubMenuStatisticsNames.invoiceManagement]: {
        name: 'invoice-management',
        id: 'a69f6ec0-1436-11ee-8045-895ba23b1271'
    }
};
