import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AgencyHandlerService} from '@app/sam-base/core';
import {subMenuMap, SubMenuStatisticsNames} from '@app/sam-base/core/enum/sub-menu-statistics.enum';
import {ProfileService} from '@shared/profile/profile.service';
import {environment} from '@root/environments/environment';
import {lastValueFrom} from 'rxjs';


@Injectable()
export class KibanaService {
    constructor(private readonly _router: Router, private _profileService: ProfileService, private _agencyService: AgencyHandlerService, private _http: HttpClient) {
    }

    /**
     * Navigate to kibana dashboard
     */
    public navigateToKibana(mode: SubMenuStatisticsNames, cliId?: string) {
        const id = subMenuMap[mode].id;
        return this._router.navigate([{
            outlets: {
                topPanel: ['kibana',
                    'load',
                    id,
                    mode,
                    cliId]
            }
        }]);
    }

    public login() {
        return this._http.get(environment.backendURL + 'kibana/auth', {responseType: 'text'});
    }

    /**
     * Returns the tenant and the selected gestion for the current user
     * to include in kibana query
     */
    public async getTenantContext(): Promise<string> {
        const tenant = await this._profileService.getTenant();
        const gestion = await lastValueFrom(this._agencyService.loadAgencyId());

        return tenant + '-' + gestion.toLowerCase();
    }
}
